import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { heroText, mobileAbout, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          boxShadow="1px 1px 3px rgba(0,0,0,.6)"
          image={`url(${mobileAbout}) center / cover no-repeat`}
          column
          center
          textCenter
        >
          <CFView
            bg="rgba(0,0,0,.7)"
            w="100%"
            pv="15px"
            column
            justifyCenter
            alignCenter
          >
            <CFView bc="#E68309" bw="3px" br="15px" pv="30px">
              <CFImage
                w="70%"
                maxWidth="350px"
                src={heroText}
                alt="Bruncheria hero text"
                zIndex={98}
              />
              <CFView mt="30px" pulsate>
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          w="100%"
          zIndex={90}
          maxWidth="1400px"
          relative
          column
          center
          textCenter
        >
          <Header />
          <iframe
            frameBorder="0"
            width="100%"
            height="700px"
            src="https://player.vimeo.com/video/708024807?h=c487ae64cb&autoplay=1&loop=1&autopause=0&muted=1&title=0&byline=0&portrait=0&controls=0"
          ></iframe>
          <CFView
            bg="rgba(0,0,0,.7)"
            w="35%"
            pv="30px"
            br="20px"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            column
            justifyCenter
            alignCenter
          >
            <CFView bc="#E68309" bw="3px" br="15px" pv="30px" ph="25px">
              <CFImage
                w="70%"
                maxWidth="350px"
                src={heroText}
                alt="Bruncheria hero text"
                zIndex={98}
              />
              <CFView mt="30px" pulsate>
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
